import {
    AirFile,
    filterFilesWithParent,
    prepareFilesToShow,
} from "../../domain/airFile/airFile";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    FilesChangeTierResult,
    FilesDownloadParams,
    FilesPort,
    FilesItemPermissions,
    FilesRePermissionParams,
    FilesSearchParams,
    FilesSearchResult,
    FilesFetchParams,
    FilesGetSharingLinkParams,
    FilesGetItemPermissionParams,
    FilesDownloadToDesktopParams,
    FilesChangeTierParams,
    FilesGetDetailsParams,
    FilesDownloadToDesktopResult,
    FilesEditTagsParams,
    FilesTagsSearchParams,
    FilesEditBatchTagsParams,
    FilesDownloadToDesktopSingleParams,
    FilesDownloadToDesktopSingleResult,
    FilesGetTiersParams,
    FilesGetTiersResult,
} from "./filesPort";
import { AirFileDetails, Tag, TagGroup } from "../../domain/airFile/details";
import { prepareSearchParams } from "../../domain/searchParams/searchParams";
import { retainNonEmptyFacets } from "../../domain/facet/facet";
import { SharingLink } from "../../domain/sharingLink/sharingLink";
import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { Job } from "../../domain/job/job";

export class FilesService {
    private readonly _filesPort: FilesPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        filesPort: FilesPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._filesPort = filesPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async getFiles(params: FilesFetchParams): Promise<Option<AirFile[]>> {
        try {
            this._errorService.clear(AirErrorKind.FetchFiles);

            this._storagePort.files.isQueryInProgress.set(true);

            const files = await this._filesPort.fetch(params);

            const filteredItems = filterFilesWithParent(
                files,
                params.folderHierarchy,
            );

            const activeFilters = this._storagePort.files.activeFilters.get();
            const { page, pageSize } =
                this._storagePort.fileExplorerMiniApp.dataParams.get();

            this._storagePort.files.files.set(files);
            this._storagePort.files.validFiles.set(filteredItems);
            this._storagePort.files.filesToShow.set(
                prepareFilesToShow(
                    filteredItems,
                    activeFilters,
                    page,
                    pageSize,
                ),
            );
            this._storagePort.files.isQueryInProgress.set(false);

            return this._storagePort.files.files.get();
        } catch (error) {
            this._storagePort.files.isQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchFiles,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getSharingLink(
        params: FilesGetSharingLinkParams,
    ): Promise<Option<SharingLink>> {
        try {
            this._errorService.clear(AirErrorKind.GetSharingLink);
            this._storagePort.files.isSharingLinkQueryInProgress.set(true);

            const link = await this._filesPort.getSharingLink(params);

            this._storagePort.files.sharingLink.set(link);

            this._storagePort.files.isSharingLinkQueryInProgress.set(false);

            return this._storagePort.files.sharingLink.get();
        } catch (error) {
            this._storagePort.files.isSharingLinkQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.GetSharingLink,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getItemPermissions(
        params: FilesGetItemPermissionParams,
    ): Promise<Option<FilesItemPermissions>> {
        try {
            this._errorService.clear(AirErrorKind.GetItemPermissions);

            const filePermissions = await this._filesPort.getItemPermissions(
                params,
            );

            this._storagePort.files.filePermissions.set(filePermissions);

            return this._storagePort.files.filePermissions.get();
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.GetItemPermissions,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async rePermission(params: FilesRePermissionParams): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.RePermission);

            await this._filesPort.rePermission(params);

            this._notificationService.show(
                "Re-permission has been started",
                "success",
            );
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.RePermission,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async download(params: FilesDownloadParams): Promise<Option<Job>> {
        try {
            this._errorService.clear(AirErrorKind.DownloadFiles);

            const job = await this._filesPort.download(params);

            this._storagePort.files.isSharingLinkQueryInProgress.set(false);

            if (params.type === "desktop") {
                this._notificationService.show(
                    `"Download to desktop" job has started, we will notify you as soon as the files are ready for downloading`,
                    "success",
                );
            } else {
                this._notificationService.show(
                    "Download has been started",
                    "success",
                );
            }

            return job;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.DownloadFiles,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async downloadToDesktop(
        params: FilesDownloadToDesktopParams,
    ): Promise<Option<FilesDownloadToDesktopResult>> {
        try {
            this._errorService.clear(AirErrorKind.DownloadFilesToDesktop);

            const res = await this._filesPort.downloadToDesktop(params);

            this._notificationService.show(
                "Download to desktop has been started",
                "success",
            );

            return res;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.DownloadFilesToDesktop,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async downloadToDesktopSingle(
        params: FilesDownloadToDesktopSingleParams,
    ): Promise<Option<FilesDownloadToDesktopSingleResult>> {
        try {
            this._errorService.clear(AirErrorKind.DownloadFilesToDesktop);

            const res = await this._filesPort.downloadToDesktopSingle(params);

            this._notificationService.show(
                "Download to desktop has been started",
                "success",
            );

            return res;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.DownloadFilesToDesktop,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async changeTier(
        params: FilesChangeTierParams,
    ): Promise<Option<FilesChangeTierResult>> {
        try {
            this._errorService.clear(AirErrorKind.ChangeTier);
            const changeTierRes = await this._filesPort.changeTier(params);

            if (Object.keys(changeTierRes.files).length === 0) {
                throw new Error(
                    "It seems that storageTier has not been changed for some reason",
                );
            }

            this._notificationService.show("Tier has been changed", "success");
            return changeTierRes;
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.ChangeTier,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getDetails(
        params: FilesGetDetailsParams,
    ): Promise<Option<AirFileDetails>> {
        try {
            this._storagePort.files.fileDetails.set(null);
            this._storagePort.files.isFileDetailsQueryInProgress.set(true);
            this._errorService.clear(AirErrorKind.Details);

            const details = await this._filesPort.getDetails(params);

            this._storagePort.files.fileDetails.set(details);
            this._storagePort.files.isFileDetailsQueryInProgress.set(false);

            return this._storagePort.files.fileDetails.get();
        } catch (error) {
            this._storagePort.files.isFileDetailsQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.Details,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async editTags(params: FilesEditTagsParams): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.EditTags);

            await this._filesPort.editTags(params);
            this._notificationService.show("Tags have been changed", "success");
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.EditTags,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async editBatchTags(params: FilesEditBatchTagsParams): Promise<void> {
        try {
            this._errorService.clear(AirErrorKind.EditBatchTags);

            await this._filesPort.editBatchTags(params);
            this._notificationService.show("Tags have been changed", "success");
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.EditBatchTags,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getTagGroups(): Promise<Option<TagGroup[]>> {
        try {
            this._storagePort.files.tagGroups.set(null);
            this._storagePort.files.isTagGroupsQueryInProgress.set(true);
            this._errorService.clear(AirErrorKind.GetTagGroups);

            const res = await this._filesPort.getTagGroups();
            this._storagePort.files.tagGroups.set(res);
            this._storagePort.files.isTagGroupsQueryInProgress.set(false);

            return this._storagePort.files.tagGroups.get();
        } catch (error) {
            this._storagePort.files.isTagGroupsQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.GetTagGroups,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async searchTags(params: FilesTagsSearchParams): Promise<Option<Tag[]>> {
        try {
            this._errorService.clear(AirErrorKind.SearchTags);

            return await this._filesPort.searchTags(params);
        } catch (error) {
            const airError = this._errorService.createAirError(
                AirErrorKind.SearchTags,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async search(
        params: FilesSearchParams,
    ): Promise<Option<FilesSearchResult>> {
        try {
            this._errorService.clear(AirErrorKind.Search);
            this._storagePort.files.isQueryInProgress.set(true);

            const searchParams = prepareSearchParams(
                params.path,
                params.query,
                params.limit,
                params.offset,
                params.sortKey,
                params.isSortDesc,
                params.filters,
            );

            const res = await this._filesPort.search(searchParams);

            res.facets = retainNonEmptyFacets(res.facets);

            this._storagePort.files.searchResult.set(res);

            if (params.filters || (params.query && params.query.length > 0)) {
                const activeFilters =
                    this._storagePort.files.activeFilters.get();
                const { page, pageSize } =
                    this._storagePort.fileExplorerMiniApp.dataParams.get();

                this._storagePort.files.searchFilesToShow.set(
                    prepareFilesToShow(
                        res.results,
                        activeFilters,
                        page,
                        pageSize,
                    ),
                );
                this._storagePort.files.searchFiles.set(res.results);
            }

            this._storagePort.files.isQueryInProgress.set(false);

            return this._storagePort.files.searchResult.get();
        } catch (error) {
            this._storagePort.files.isQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.Search,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getTiers(
        params: FilesGetTiersParams,
    ): Promise<Option<FilesGetTiersResult>> {
        try {
            this._errorService.clear(AirErrorKind.FilesGetTiers);
            this._storagePort.files.isGetTiersQueryInProgress.set(true);

            const res = await this._filesPort.getTiers(params);

            this._storagePort.files.isGetTiersQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._storagePort.files.isGetTiersQueryInProgress.set(false);
            const airError = this._errorService.createAirError(
                AirErrorKind.FilesGetTiers,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async requestAccess(): Promise<void> {
        console.log("requestAccess");
    }
}
