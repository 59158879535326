import logoSP from "../../ui/assets/logo-sp.svg";

export type ConfigUploaderIgnoredPath = {
    label: string;
    template: string;
    isEnabled: boolean;
};

export enum ConfigKey {
    Uploader = "uploader",
    Sharing = "shareLinkUiConfiguration",
    Branding = "branding",
    Preview = "preview",
    AI = "ai",
}

/**
 * Can be called anonymously (without authorization)
 */
export type InitConfig = {
    title: string | null;
    icon: string | null;
    logo: string | null;
    primaryColor: string | null;
    darkModePrimaryColor: string | null;
    /**
     * @deprecated
     */
    version: string | null;
};

/**
 * Can be called only after authorization
 */
export type UIConfig = {
    features: {
        shareLinkUiConfiguration: ShareLinkUIConfig;
        uploader: UploaderConfig;
        /**
         * @deprecated
         */
        sharing: boolean;
    };
    signalREndpoint: string;
    version: string | null;
};

export type BrandingConfig = Omit<InitConfig, "version">;

export type ShareLinkUIConfig = {
    enabled: boolean;
    plainLink: boolean;
    password: boolean;
    domainOnly: boolean;
    emailNotifications: boolean;
    assetAccessRequestEnabled: boolean;
    assetAccessRequestRecipients: string[];
};

export type UploaderConfig = {
    uploading: {
        maxConcurrentUploads: number;
        retryCount: number;
        retryDelay: string;
    };
    ignoredPaths: ConfigUploaderIgnoredPath[];
    parsingCameraCard: boolean;
    addMetadata: boolean;
    folderNamingConvention: string;
};

export type Config = InitConfig & UIConfig;

export type AIFeatureConfig = {
    disabled: boolean;
};

export function getDefaultConfig(): Config {
    return {
        title: null,
        icon: logoSP,
        logo: logoSP,
        primaryColor: "#115f76",
        darkModePrimaryColor: "#09566d",
        version: null,
        signalREndpoint: "",

        features: {
            shareLinkUiConfiguration: {
                enabled: false,
                plainLink: false,
                password: false,
                domainOnly: false,
                emailNotifications: false,
                assetAccessRequestEnabled: false,
                assetAccessRequestRecipients: [],
            },
            uploader: {
                uploading: {
                    maxConcurrentUploads: 5,
                    retryCount: 3,
                    retryDelay: "10",
                },
                ignoredPaths: [],
                parsingCameraCard: true,
                addMetadata: false,
                folderNamingConvention: "",
            },

            sharing: false,
        },
    };
}
