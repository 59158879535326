import {
    AlertOffFilled,
    AlertOffRegular,
    ArrowClockwiseFilled,
    ArrowClockwiseRegular,
    ClipboardTextEditFilled,
    CloudArchiveFilled,
    CloudArchiveRegular,
    CloudArrowUpFilled,
    CloudArrowUpRegular,
    CollectionsFilled,
    CollectionsRegular,
    DeleteFilled,
    DeleteRegular,
    DocumentAddFilled,
    DocumentAddRegular,
    FolderAddFilled,
    FolderAddRegular,
    FolderFilled,
    FolderOpenFilled,
    FolderOpenRegular,
    FolderRegular,
    FolderSyncFilled,
    FolderSyncRegular,
    Home24Filled,
    Home24Regular,
    MailAttachFilled,
    MailAttachRegular,
    QuestionCircleFilled,
    QuestionCircleRegular,
    SettingsFilled,
    SettingsRegular,
    TextBoxSettingsFilled,
    TextBoxSettingsRegular,
    TextBulletListSquareEditRegular,
    bundleIcon,
} from "@fluentui/react-icons";

export const RefreshFolderIcon = bundleIcon(
    FolderSyncFilled,
    FolderSyncRegular,
);
export const NewFolderIcon = bundleIcon(FolderAddFilled, FolderAddRegular);
export const FolderIcon = bundleIcon(FolderFilled, FolderRegular);
export const LocationIcon = bundleIcon(CloudArchiveFilled, CloudArchiveRegular);
export const AlertOff = bundleIcon(AlertOffFilled, AlertOffRegular);
export const FolderOpenIcon = bundleIcon(FolderOpenFilled, FolderOpenRegular);
export const CollectionsIcon = bundleIcon(
    CollectionsFilled,
    CollectionsRegular,
);
export const UploaderIcon = bundleIcon(CloudArrowUpFilled, CloudArrowUpRegular);
export const AdminIcon = bundleIcon(SettingsFilled, SettingsRegular);
export const JobsIcon = bundleIcon(
    TextBoxSettingsFilled,
    TextBoxSettingsRegular,
);
export const EditIcon = bundleIcon(
    ClipboardTextEditFilled,
    TextBulletListSquareEditRegular,
);
export const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const AddDataIcon = bundleIcon(DocumentAddFilled, DocumentAddRegular);
export const HomeIcon = bundleIcon(Home24Filled, Home24Regular);
export const RefreshIcon = bundleIcon(
    ArrowClockwiseFilled,
    ArrowClockwiseRegular,
);
export const MailAttachIcon = bundleIcon(MailAttachFilled, MailAttachRegular);
export const QuestionCircleIcon = bundleIcon(
    QuestionCircleFilled,
    QuestionCircleRegular,
);
