import {
    Toolbar,
    ToolbarButton,
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    useOverflowMenu,
    useIsOverflowItemVisible,
    MenuItemProps,
    makeStyles,
    tokens,
    ToolbarRadioButton,
    ToolbarRadioGroup,
    MenuItemRadio,
    MenuDivider,
    useIsOverflowGroupVisible,
    ToolbarGroup,
    shorthands,
    MenuButton,
    Text,
    AccordionHeader,
    AccordionPanel,
    AccordionItem,
    Accordion,
} from "@fluentui/react-components";
import React from "react";
import {
    MoreHorizontal20Filled,
    GridRegular,
    ListRegular,
    AddRegular,
    DismissRegular,
    PanelRightContractRegular,
    PanelLeftExpandRegular,
    DeleteRegular,
    ArrowDownloadRegular,
    DatabaseSwitchRegular,
    TextBulletListSquareEditRegular,
} from "@fluentui/react-icons";
import { FilesViewModeKind } from "../../../../models";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { router } from "../../../../router";
import { CollectionItem } from "../../../../../domain/collection/collections";
import { Tier, tiers } from "../../../../models/tiers";
import { AirFile, isFile } from "../../../../../domain/airFile/airFile";
import {
    hasUserChangeTierPermissions,
    hasUserDownloadPermissions,
} from "../../../../../domain/user/user";
import { DownloadLocation } from "../../../../../domain/downloadLocation/downloadLocation";
import { useFiles } from "../../../../../application/useCases/useFiles";
import { Option } from "../../../../../domain/types/types";
import CreateCollectionDialog from "../../shared/CreateCollectionDialog/CreateCollectionDialog";
import { useCollections } from "../../../../../application/useCases/useCollections";
import DeleteFilesFromCollectionDialog from "../DeleteFilesFromCollectionDialog";
import { openDownloadDialog } from "../../shared/DownloadDialog/DownloadDialog";
import { openBulkTaggingPanel } from "../../shared/BulkTaggingPanel/BulkTaggingPanel";
import { useJobs } from "../../../../../application/useCases/useJobs";
import { formatSize } from "../../../../../utility";
import { defaultDataParams } from "../../../../../domain/dataParams/dataParams";
import {
    MailAttachIcon,
    RefreshIcon,
} from "../../../../components/BundledIcons";
import RequestAccessDialog from "../../shared/RequestAccessDialog/RequestAccessDialog";

const useStyles = makeStyles({
    toolBar: {
        ...shorthands.overflow("hidden"),
        display: "grid",
        ...shorthands.padding("4px", "0"),
        gridAutoFlow: "column",
        justifyContent: "space-between",
    },
    toggleButton: {
        "& span": {
            color: `${tokens.colorNeutralForeground1}`,
        },
        ":hover": {
            "& span": {
                color: `${tokens.colorBrandForeground1}`,
            },
        },
    },
});

function onSetFilesViewMode(viewMode: FilesViewModeKind): void {
    router.setSearchParams([{ key: "viewMode", value: viewMode.toString() }]);
}

function onSelectListView(): void {
    onSetFilesViewMode(FilesViewModeKind.List);
}

function onSelectTileView(): void {
    onSetFilesViewMode(FilesViewModeKind.Tiles);
}

function onCreateCollection(): void {
    state.appDialog.set(() => (
        <CreateCollectionDialog
            isOpen={true}
            onAfterApply={async (): Promise<void> => {
                await useCollections.fetch(
                    useStorage.collectionsMiniApp.dataParams.get(),
                );
            }}
        />
    ));
}

function onToggleAppPanel(): void {
    state.appPanel.isOpen.set(!state.appPanel.isOpen.get());
}

function onDelete(item: CollectionItem): void {
    state.appDialog.set(() => (
        <DeleteFilesFromCollectionDialog
            title="Delete from collection"
            WarningText={(): JSX.Element => (
                <div className="app-dialog__content">
                    <Text>
                        You going to delete{" "}
                        <strong>
                            {useStorage.collectionsMiniApp.selection.get().size}{" "}
                            file(s)
                        </strong>{" "}
                        from <strong>{item.title}</strong> collection.
                    </Text>

                    <Accordion collapsible>
                        <AccordionItem value="1">
                            <AccordionHeader>Files</AccordionHeader>
                            <AccordionPanel>
                                <div className="dialog-details">
                                    {Array.from(
                                        useStorage.collectionsMiniApp.selection
                                            .get()
                                            .values(),
                                    ).map(i => (
                                        <Text key={i.id}>{i.title}</Text>
                                    ))}
                                </div>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            )}
            onDelete={async (): Promise<void> => {
                await useCollections.deleteFiles({
                    id: item.id,
                    assets: Array.from(
                        useStorage.collectionsMiniApp.selection.get().values(),
                    ).map(i => i.id),
                    userName: useStorage.authorization.user.get()!.name,
                });

                useStorage.collectionsMiniApp.selection.set(new Map());
                const collection = await useCollections.fetchById({
                    id: item.id,
                });

                // TODO: hotfix
                if (collection && collection.items.length > 0) {
                    await useFiles.getFiles({
                        ids: collection.items,
                    });
                } else {
                    useStorage.files.files.set([]);
                }
            }}
        />
    ));
}

// TODO: refactor; duplicates (FileExplorerCommandBar.tsx)
async function onChangeStorageTier(
    tier: Tier,
    filesSelectionFiles: AirFile[],
    onRefresh: () => Promise<void>,
): Promise<void> {
    const res = await useFiles.changeTier({
        ids: filesSelectionFiles.map(i => i.id),
        target: tier.key,
    });

    if (res !== undefined && res !== null) {
        await onRefresh();

        useStorage.jobs.dataParams.set({ ...defaultDataParams });
        await useJobs.fetchAll({
            limit: defaultDataParams.pageSize,
            offset: 0,
        });
    }
}

interface ToolbarOverflowMenuItemProps extends Omit<MenuItemProps, "id"> {
    id: string;
    item: Option<CollectionItem>;
    onRefresh: () => Promise<void>;
}

const ToolbarOverflowMenuItem: React.FC<
    ToolbarOverflowMenuItemProps
> = props => {
    const user = state.useState(useStorage.authorization.user);
    const { id, ...rest } = props;
    const filesSelection = state.useState(
        useStorage.collectionsMiniApp.selection,
    );
    const filesSelectionFiles = Array.from(filesSelection.values());
    const filesSelectionFilesSize = filesSelectionFiles.reduce(
        (sum, obj) => sum + (obj.fileSize ?? 0),
        0,
    );
    const filesViewMode = state.useState(
        state.collectionsMiniApp.filesViewMode,
    );
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );

    const tabs = state.useState(state.appPanel.tabs, {
        notifyCondition: s => s.size !== state.appPanel.tabs.get().size,
    });
    const isOpen = state.useState(state.appPanel.isOpen);
    const requestAssetsFeature = state.useState(useStorage.config.config, {
        properties: ["features.assetAccessRequestEnabled"],
    }).features.shareLinkUiConfiguration.assetAccessRequestEnabled;

    const isVisible = useIsOverflowItemVisible(id);
    const classes = useStyles();

    const onRequestAssets = (): void => {
        if (filesSelection.size > 0 && user) {
            state.appDialog.set(() => (
                <RequestAccessDialog
                    filesSelection={filesSelection}
                    user={user}
                    isOpen={true}
                    onClose={(): void => {
                        state.appDialog.set(null);
                    }}
                />
            ));
        }
    };

    if (isVisible) {
        return null;
    }

    if (id.includes("refresh")) {
        return (
            <MenuItem
                aria-label="Refresh files"
                icon={<RefreshIcon />}
                onClick={props.onRefresh}
                disabled={isFilesQueryInProgress}
            >
                Refresh
            </MenuItem>
        );
    }

    if (id.includes("requestAssets") && filesSelection.size > 0) {
        return (
            <MenuItem
                aria-label="Email as list"
                icon={<MailAttachIcon />}
                onClick={onRequestAssets}
                disabled={isFilesQueryInProgress || !requestAssetsFeature}
            >
                Email as list
            </MenuItem>
        );
    }

    if (id.includes("collection")) {
        return (
            <>
                {filesSelection.size > 0 ? (
                    <>
                        {props.item !== null && props.item !== undefined ? (
                            <MenuItem
                                icon={<DeleteRegular />}
                                onClick={(): void => onDelete(props.item!)}
                            >
                                Remove from collection
                            </MenuItem>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    if (id.includes("downloads")) {
        return (
            <>
                {user !== null &&
                    user !== undefined &&
                    hasUserDownloadPermissions(filesSelectionFiles, user) && (
                        <Menu openOnHover={false}>
                            <MenuTrigger disableButtonEnhancement>
                                <MenuItem icon={<ArrowDownloadRegular />}>
                                    Download
                                </MenuItem>
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    <DestinationsList
                                        destinations={user!.downloadLocations}
                                        selectionSize={filesSelectionFilesSize}
                                        selection={filesSelectionFiles}
                                        onSelectDestination={
                                            onSelectDownloadLocation
                                        }
                                    />
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    )}
            </>
        );
    }

    if (id.includes("changeStorageTier")) {
        return (
            <>
                {user !== null &&
                    user !== undefined &&
                    hasUserChangeTierPermissions(filesSelectionFiles, user) && (
                        <Menu openOnHover={false}>
                            <MenuTrigger disableButtonEnhancement>
                                <MenuItem icon={<DatabaseSwitchRegular />}>
                                    Change storage tier
                                </MenuItem>
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    {tiers.map(i => (
                                        <MenuItem
                                            key={i.key}
                                            icon={i.iconProps}
                                            onClick={async (): Promise<void> => {
                                                onChangeStorageTier(
                                                    i,
                                                    filesSelectionFiles,
                                                    props.onRefresh,
                                                );
                                            }}
                                        >
                                            {i.text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    )}
            </>
        );
    }

    if (id.includes("selection")) {
        return (
            <>
                {filesSelection.size > 0 ? (
                    <MenuItem
                        icon={<DismissRegular />}
                        onClick={(): void => {
                            router.removeSearchParam("selection");
                        }}
                    >
                        Selected {filesSelection.size}
                    </MenuItem>
                ) : null}
            </>
        );
    }

    if (id.includes("bulkTagging")) {
        return (
            <>
                {filesSelection.size > 1 ? (
                    <MenuItem
                        icon={<TextBulletListSquareEditRegular />}
                        onClick={(): void =>
                            openBulkTaggingPanel(filesSelection)
                        }
                    >
                        Bulk tagging
                    </MenuItem>
                ) : null}
            </>
        );
    }

    if (id.includes("viewMode")) {
        return (
            <MenuList
                checkedValues={{
                    viewModeBtn2:
                        filesViewMode === FilesViewModeKind.List
                            ? ["tableView"]
                            : ["tileView"],
                }}
            >
                <MenuItemRadio
                    className={classes.toggleButton}
                    aria-label="Table view"
                    name="viewModeBtn2"
                    value="tableView"
                    icon={<ListRegular />}
                    onClick={onSelectListView}
                >
                    Table view
                </MenuItemRadio>
                <MenuItemRadio
                    className={classes.toggleButton}
                    aria-label="Tile view"
                    name="viewModeBtn2"
                    value="tileView"
                    icon={<GridRegular />}
                    onClick={onSelectTileView}
                >
                    Tile view
                </MenuItemRadio>
            </MenuList>
        );
    }

    if (id.includes("toggleAppPanel")) {
        return (
            <MenuItem
                onClick={(): void => {
                    state.appPanel.isOpen.set(!state.appPanel.isOpen.get());
                }}
                disabled={tabs.size === 0}
                icon={
                    isOpen ? (
                        <PanelRightContractRegular />
                    ) : (
                        <PanelLeftExpandRegular style={{ rotate: "-180deg" }} />
                    )
                }
            >
                {isOpen ? "Close" : "Open"} app panel
            </MenuItem>
        );
    }

    return <MenuItem {...(rest as MenuItemProps)}>Item {id}</MenuItem>;
};

const ToolbarMenuOverflowDivider: React.FC<{
    id: string;
}> = props => {
    const isGroupVisible = useIsOverflowGroupVisible(props.id);

    if (isGroupVisible === "visible") {
        return null;
    }

    return <MenuDivider />;
};

const OverflowMenu: React.FC<{
    itemIds: Array<Array<string>>;
    item: Option<CollectionItem>;
    onRefresh: () => Promise<void>;
}> = ({ itemIds, item, onRefresh }) => {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {itemIds.map((group, groupIndx) => {
                        const isLast = groupIndx === itemIds.length - 1;
                        return (
                            <React.Fragment key={group.join()}>
                                {group.map(i => (
                                    <ToolbarOverflowMenuItem
                                        key={i}
                                        id={i}
                                        item={item}
                                        onRefresh={onRefresh}
                                    />
                                ))}

                                {!isLast && (
                                    <ToolbarMenuOverflowDivider
                                        id={`${groupIndx + 1}`}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

type ToolbarOverflowItemProps = {
    children: JSX.Element;
    overflowId: string;
    overflowGroupId?: string;
};

function ToolbarOverflowItem(props: ToolbarOverflowItemProps): JSX.Element {
    return (
        <OverflowItem id={props.overflowId} groupId={props.overflowGroupId}>
            {props.children}
        </OverflowItem>
    );
}

type CollectionsCommandBarProps = {
    onRefresh: () => Promise<void>;
    disabled: boolean;
    item: Option<CollectionItem>;
    isCommandBarForCollections: boolean;
    updateStateAfterDataFetch: (id: string | null) => void;
};

type DestinationsListProps = {
    destinations: DownloadLocation[];
    selection: AirFile[];
    selectionSize: number;
    onSelectDestination: (
        dest: DownloadLocation,
        filesSelectionFiles: AirFile[],
        filesSelectionFilesSize: number,
    ) => void;
};

function DestinationsList(props: DestinationsListProps): JSX.Element {
    return (
        <>
            {props.destinations.map(dest => {
                const disabled = dest.limit
                    ? dest.limit > 0 && props.selectionSize > dest.limit
                    : false;

                return (
                    <MenuItem
                        key={dest.id}
                        disabled={disabled}
                        onClick={(): void =>
                            props.onSelectDestination(
                                dest,
                                props.selection,
                                props.selectionSize,
                            )
                        }
                    >
                        {disabled
                            ? `${dest.title} (Limit ${formatSize(dest.limit!)})`
                            : dest.title}
                    </MenuItem>
                );
            })}
        </>
    );
}

function onSelectDownloadLocation(
    dest: DownloadLocation,
    filesSelectionFiles: AirFile[],
    filesSelectionFilesSize: number,
): void {
    useStorage.collectionsMiniApp.selectedDownloadLocation.set(dest);
    openDownloadDialog(dest, filesSelectionFiles, filesSelectionFilesSize);
}

function CollectionsCommandBar(props: CollectionsCommandBarProps): JSX.Element {
    const user = state.useState(useStorage.authorization.user);
    const filesSelection = state.useState(
        useStorage.collectionsMiniApp.selection,
    );
    const filesViewMode = state.useState(
        state.collectionsMiniApp.filesViewMode,
    );
    const tabs = state.useState(state.appPanel.tabs);
    const isOpen = state.useState(state.appPanel.isOpen);
    const requestAssetsFeature = state.useState(useStorage.config.config, {
        properties: ["features.assetAccessRequestEnabled"],
    }).features.shareLinkUiConfiguration.assetAccessRequestEnabled;

    const classes = useStyles();

    const filesSelectionFiles = Array.from(filesSelection.values());
    const filesSelectionFilesSize = filesSelectionFiles.reduce(
        (sum, obj) => sum + (obj.fileSize ?? 0),
        0,
    );
    const selectionText: string | null =
        filesSelection.size === 0
            ? null
            : filesSelectionFiles.some(i => !isFile(i))
            ? `Selected ${filesSelection.size} item(s)`
            : `Selected ${filesSelection.size} item(s) (${formatSize(
                  filesSelectionFilesSize,
              )})`;

    const onRequestAssets = (): void => {
        if (filesSelection.size > 0 && user) {
            state.appDialog.set(() => (
                <RequestAccessDialog
                    filesSelection={filesSelection}
                    user={user}
                    isOpen={true}
                    onClose={(): void => {
                        state.appDialog.set(null);
                    }}
                />
            ));
        }
    };

    return (
        <Overflow padding={90}>
            <Toolbar
                aria-label="Collections Command bar"
                defaultCheckedValues={{
                    viewModeBtn:
                        filesViewMode == FilesViewModeKind.List
                            ? ["tableView"]
                            : ["tileView"],
                }}
                className={classes.toolBar}
            >
                <ToolbarGroup className="command-bar__main-container">
                    {props.isCommandBarForCollections ? (
                        <ToolbarOverflowItem
                            overflowId="collection"
                            overflowGroupId="1"
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<AddRegular />}
                                onClick={onCreateCollection}
                                disabled={props.disabled}
                            >
                                Add new collection
                            </ToolbarButton>
                        </ToolbarOverflowItem>
                    ) : null}

                    {filesSelection.size > 0 ? (
                        <>
                            {props.item !== null && props.item !== undefined ? (
                                <>
                                    {useStorage.authorization.user.get() !==
                                        null &&
                                    useStorage.authorization.user.get()!
                                        .name === props.item.userName ? (
                                        <ToolbarOverflowItem
                                            overflowId="collection"
                                            overflowGroupId="1"
                                        >
                                            <ToolbarButton
                                                appearance="subtle"
                                                icon={<DeleteRegular />}
                                                onClick={(): void =>
                                                    onDelete(props.item!)
                                                }
                                            >
                                                Delete from collection
                                            </ToolbarButton>
                                        </ToolbarOverflowItem>
                                    ) : null}

                                    {user !== null &&
                                        user !== undefined &&
                                        hasUserDownloadPermissions(
                                            filesSelectionFiles,
                                            user,
                                        ) && (
                                            <ToolbarOverflowItem
                                                overflowId="downloads"
                                                overflowGroupId="1"
                                            >
                                                <div>
                                                    <Menu>
                                                        <MenuTrigger
                                                            disableButtonEnhancement
                                                        >
                                                            <MenuButton
                                                                appearance="subtle"
                                                                icon={
                                                                    <ArrowDownloadRegular />
                                                                }
                                                            >
                                                                Download
                                                            </MenuButton>
                                                        </MenuTrigger>

                                                        <MenuPopover>
                                                            <MenuList>
                                                                <DestinationsList
                                                                    destinations={
                                                                        user!
                                                                            .downloadLocations
                                                                    }
                                                                    selection={
                                                                        filesSelectionFiles
                                                                    }
                                                                    selectionSize={
                                                                        filesSelectionFilesSize
                                                                    }
                                                                    onSelectDestination={
                                                                        onSelectDownloadLocation
                                                                    }
                                                                />
                                                            </MenuList>
                                                        </MenuPopover>
                                                    </Menu>
                                                </div>
                                            </ToolbarOverflowItem>
                                        )}

                                    {user !== null &&
                                        user !== undefined &&
                                        hasUserChangeTierPermissions(
                                            filesSelectionFiles,
                                            user,
                                        ) && (
                                            <ToolbarOverflowItem
                                                overflowId="changeStorageTier"
                                                overflowGroupId="1"
                                            >
                                                <div>
                                                    <Menu>
                                                        <MenuTrigger
                                                            disableButtonEnhancement
                                                        >
                                                            <MenuButton
                                                                appearance="subtle"
                                                                icon={
                                                                    <DatabaseSwitchRegular />
                                                                }
                                                            >
                                                                Change storage
                                                                tier
                                                            </MenuButton>
                                                        </MenuTrigger>

                                                        <MenuPopover>
                                                            <MenuList>
                                                                {tiers.map(
                                                                    i => (
                                                                        <MenuItem
                                                                            key={
                                                                                i.key
                                                                            }
                                                                            icon={
                                                                                i.iconProps
                                                                            }
                                                                            onClick={async (): Promise<void> => {
                                                                                onChangeStorageTier(
                                                                                    i,
                                                                                    filesSelectionFiles,
                                                                                    props.onRefresh,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {
                                                                                i.text
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )}
                                                            </MenuList>
                                                        </MenuPopover>
                                                    </Menu>
                                                </div>
                                            </ToolbarOverflowItem>
                                        )}
                                </>
                            ) : null}

                            {requestAssetsFeature &&
                                filesSelectionFiles.every(i => isFile(i)) && (
                                    <ToolbarOverflowItem
                                        overflowId="requestAssets"
                                        overflowGroupId="1"
                                    >
                                        <ToolbarButton
                                            aria-label="Email as list"
                                            appearance="subtle"
                                            icon={<MailAttachIcon />}
                                            onClick={onRequestAssets}
                                            disabled={!requestAssetsFeature}
                                        >
                                            Email as list
                                        </ToolbarButton>
                                    </ToolbarOverflowItem>
                                )}

                            <ToolbarOverflowItem
                                overflowId="selection"
                                overflowGroupId="2"
                            >
                                <ToolbarButton
                                    appearance="subtle"
                                    icon={<DismissRegular />}
                                    onClick={(): void => {
                                        router.removeSearchParam("selection");
                                    }}
                                >
                                    {selectionText}
                                </ToolbarButton>
                            </ToolbarOverflowItem>
                        </>
                    ) : null}

                    {filesSelection.size > 1 ? (
                        <ToolbarOverflowItem
                            overflowId="bulkTagging"
                            overflowGroupId="2"
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<TextBulletListSquareEditRegular />}
                                onClick={(): void =>
                                    openBulkTaggingPanel(filesSelection)
                                }
                            >
                                Bulk tagging
                            </ToolbarButton>
                        </ToolbarOverflowItem>
                    ) : null}

                    <ToolbarOverflowItem
                        overflowId="refresh"
                        overflowGroupId="1"
                    >
                        <ToolbarButton
                            aria-label="Refresh files"
                            appearance="subtle"
                            icon={<RefreshIcon />}
                            onClick={props.onRefresh}
                            disabled={props.disabled}
                        >
                            Refresh
                        </ToolbarButton>
                    </ToolbarOverflowItem>
                </ToolbarGroup>

                <ToolbarGroup className="command-bar__far-container">
                    <ToolbarOverflowItem
                        overflowId="viewMode"
                        overflowGroupId="2"
                    >
                        <ToolbarRadioGroup>
                            <ToolbarRadioButton
                                className={classes.toggleButton}
                                aria-label="Table view"
                                name="viewModeBtn"
                                value="tableView"
                                icon={<ListRegular />}
                                appearance="subtle"
                                onClick={onSelectListView}
                                title="List view"
                            />
                            <ToolbarRadioButton
                                className={classes.toggleButton}
                                aria-label="Tile view"
                                name="viewModeBtn"
                                value="tileView"
                                icon={<GridRegular />}
                                appearance="subtle"
                                onClick={onSelectTileView}
                                title="Tiles view"
                            />
                        </ToolbarRadioGroup>
                    </ToolbarOverflowItem>

                    <ToolbarOverflowItem
                        overflowId="toggleAppPanel"
                        overflowGroupId="3"
                    >
                        <ToolbarButton
                            name="toggleAppPanel"
                            onClick={onToggleAppPanel}
                            disabled={tabs.size === 0}
                            title={isOpen ? "Close panel" : "Open panel"}
                            icon={
                                isOpen ? (
                                    <PanelRightContractRegular />
                                ) : (
                                    <PanelLeftExpandRegular
                                        style={{ rotate: "-180deg" }}
                                    />
                                )
                            }
                        />
                    </ToolbarOverflowItem>
                </ToolbarGroup>

                <OverflowMenu
                    itemIds={[
                        [
                            "collection",
                            "downloads",
                            "changeStorageTier",
                            "requestAssets",
                        ],
                        ["selection", "bulkTagging", "refresh"],
                        ["viewMode"],
                        ["toggleAppPanel"],
                    ]}
                    item={props.item}
                    onRefresh={props.onRefresh}
                />
            </Toolbar>
        </Overflow>
    );
}

export default CollectionsCommandBar;
