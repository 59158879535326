import {
    makeStyles,
    mergeClasses,
    Subtitle2,
    Button,
    CounterBadge,
    Menu,
    MenuTrigger,
    Avatar,
    MenuPopover,
    MenuList,
    MenuItem,
} from "@fluentui/react-components";
import {
    AlertRegular,
    CloudSyncRegular,
    PersonSettingsRegular,
    SignOutRegular,
} from "@fluentui/react-icons";
import { tokens } from "@fluentui/react-theme";
import React, { useEffect } from "react";
import { useAuthorization } from "../../../application/useCases/useAuthorization";
import { AppPanelKind } from "../../models";
import logoSP from "../../assets/logo-sp.svg";
import "./appHeader.scss";
import UserSettings from "../../components/UserSettings/UserSettings";
import { User } from "../../../domain/user/user";
import { state } from "../../state/stateAdapter";
import { useStorage } from "../../../application/useCases/useStorage";
import AppSearch from "../AppSearch/AppSearch";
import { appManager } from "../../appManager/appManager";
import { Option } from "../../../domain/types/types";
import AppNotifications from "../AppNotifications/AppNotifications";
import { useInterface } from "../../../application/useCases/useInterface";
import appUploadsTab from "../AppUploadsTab/appUploadsTab";
import { QuestionCircleIcon } from "../../components/BundledIcons";

export const useStyles = makeStyles({
    header: { backgroundColor: tokens.colorBrandBackground },
    commandButton: {
        width: "48px",
    },
    userButton: {
        display: "grid",
        gridAutoFlow: "column",
        columnGap: "8px",
        alignItems: "center",
        alignContent: "center",
    },
    button: {
        maxWidth: "100%",
        minWidth: "48px",
        height: "48px",
        paddingTop: 0,
        borderSize: 0,
        paddingBottom: 0,
        position: "relative",
        ":hover": {
            backgroundColor: tokens.colorSubtleBackgroundInvertedHover,
        },
    },
    whiteClr: {
        color: "#ffffff",
    },
    darkBackground: {
        backgroundColor: tokens.colorBrandBackgroundHover,
    },
    ringer: {
        ":hover": {
            "& #ringerBadge": {
                backgroundColor: "#ffffff",
                color: "#000000",
            },
        },
    },
    ringerBadge: {
        position: "absolute",
        top: "3px",
        right: "8px",
    },
    txtInfo: {
        ":hover": {
            backgroundColor: "transparent",
        },
    },
    txtReadonly: {
        cursor: "default",
    },
});

function onSignOut(): void {
    useAuthorization.signOut();
}

type AppHeaderProps = {
    user: Option<User>;
};

function handleHelpClick(): void {
    const base_url = `${window.location.protocol}//${window.location.host}`;
    const helpUrl = `${base_url}/help`;

    window.open(helpUrl, "_blank");
}

function AppHeader(props: AppHeaderProps): JSX.Element | null {
    const classes = useStyles();
    const user = props.user;
    const drawerNotifications = state.useState(state.drawerNotifications);
    const config = state.useState(useStorage.config.config);
    const uploads = state.useState(useStorage.uploaderMiniApp.upload);

    const uploadsDisabled =
        uploads.aborted.size === 0 &&
        uploads.completed.size === 0 &&
        uploads.paused.size === 0 &&
        uploads.pending.size === 0 &&
        uploads.uploading.size === 0;

    const fullVersion: string | null = config.version;
    const shortVersion: string | null = fullVersion
        ? fullVersion.split(".").slice(0, 2).join(".")
        : null;

    useEffect(() => {
        if (uploadsDisabled) {
            appManager.deleteTab(appUploadsTab.id);
        }
    }, [uploadsDisabled]);

    if (!user) {
        return null;
    }

    const userNameSplit = user.name.split(" ");
    const userNameSplitLength = userNameSplit.length;
    const initials =
        userNameSplitLength > 1
            ? userNameSplit
                  .map(i => i[0])
                  .join("")
                  .toUpperCase()
            : userNameSplit[0][0].toUpperCase();

    return (
        // TODO: add bubbling (open appPanel)
        <header className={mergeClasses(classes.header, "header")}>
            <div className="header__logo">
                <img
                    className="header__logo-img"
                    height={32}
                    width={32}
                    src={config.logo ?? logoSP}
                    alt="sp logo"
                />
                {config.title && (
                    <Subtitle2
                        truncate
                        block
                        wrap={false}
                        as="h1"
                        className={classes.whiteClr}
                        title={config.title}
                    >
                        {config.title}
                    </Subtitle2>
                )}
            </div>

            <div className={mergeClasses("header__search")}>
                <AppSearch />
            </div>

            <div className="header__actions">
                {/* TODO: Move ringer to separate component */}

                <Button
                    appearance="transparent"
                    shape="square"
                    icon={
                        <CloudSyncRegular
                            className={
                                uploadsDisabled ? "txt_muted" : classes.whiteClr
                            }
                        />
                    }
                    title="Uploads"
                    className={mergeClasses(
                        classes.button,
                        classes.commandButton,
                        classes.ringer,
                    )}
                    disabled={uploadsDisabled}
                    onClick={(): void => appManager.toggleTab(appUploadsTab)}
                >
                    <CounterBadge
                        id="uploadsBadge"
                        count={uploads.completed.size}
                        className={mergeClasses(
                            classes.darkBackground,
                            classes.ringerBadge,
                        )}
                    />
                </Button>

                <Button
                    appearance="transparent"
                    shape="square"
                    className={mergeClasses(
                        classes.button,
                        classes.commandButton,
                        classes.ringer,
                    )}
                    icon={<AlertRegular className={classes.whiteClr} />}
                    data-panel-kind={AppPanelKind.Notifications}
                    onClick={(): void =>
                        appManager.toggleTab({
                            id: "notifications",
                            title: "Notifications",
                            content: <AppNotifications />,
                        })
                    }
                    title="App notifications"
                >
                    <CounterBadge
                        id="ringerBadge"
                        count={drawerNotifications.size}
                        className={mergeClasses(
                            classes.darkBackground,
                            classes.ringerBadge,
                        )}
                    />
                </Button>

                <Button
                    appearance="transparent"
                    shape="square"
                    className={mergeClasses(
                        classes.button,
                        classes.commandButton,
                    )}
                    icon={
                        <PersonSettingsRegular className={classes.whiteClr} />
                    }
                    data-panel-kind={AppPanelKind.Settings}
                    onClick={(): void => {
                        appManager.toggleTab({
                            id: "userSettings",
                            title: "User settings",
                            content: <UserSettings />,
                        });
                    }}
                    title="User settings"
                />

                <Button
                    appearance="transparent"
                    shape="square"
                    className={mergeClasses(
                        classes.button,
                        classes.commandButton,
                    )}
                    icon={<QuestionCircleIcon className={classes.whiteClr} />}
                    title="Help Page"
                    onClick={handleHelpClick}
                />

                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            appearance="transparent"
                            shape="square"
                            className={mergeClasses(
                                classes.button,
                                classes.userButton,
                            )}
                            title={user.name}
                        >
                            <Avatar
                                image={{
                                    src: `data:image/jpeg;base64,${user.photo}`,
                                }}
                                initials={initials}
                                name={user.name}
                            />
                        </Button>
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem
                                className={mergeClasses(
                                    classes.txtInfo,
                                    classes.txtReadonly,
                                    "txt_muted",
                                )}
                                aria-readonly
                                persistOnClick={true}
                            >
                                {user.name}
                            </MenuItem>
                            {config.version !== null ? (
                                <MenuItem
                                    className={mergeClasses(
                                        classes.txtInfo,
                                        "txt_muted",
                                    )}
                                    aria-readonly
                                    persistOnClick={true}
                                    onClick={(): Promise<void> =>
                                        useInterface.copyToClipboard(
                                            config.version ?? "",
                                        )
                                    }
                                    title={
                                        fullVersion !== null
                                            ? `Version ${fullVersion}`
                                            : "No version found"
                                    }
                                >
                                    ver. {shortVersion}
                                </MenuItem>
                            ) : null}

                            <MenuItem
                                icon={<SignOutRegular />}
                                onClick={onSignOut}
                            >
                                Sign out
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        </header>
    );
}

export default AppHeader;
