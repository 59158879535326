import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import {
    AIFeatureConfig,
    BrandingConfig,
    ConfigKey,
    ShareLinkUIConfig,
} from "../config/config";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    PreviewBaseInfo,
    PreviewUpdatePreviewParams,
} from "./adminFeaturePreview";
import { AdminPort } from "./adminPort";
import { AdminStoragePort } from "./adminStoragePort";

export class AdminService {
    private readonly _adminPort: AdminPort;
    private readonly _errorService: AirErrorService;
    private readonly _adminStoragePort: AdminStoragePort;
    private readonly _notificationService: NotificationService;
    private readonly _storageAdapter: StoragePort;

    constructor(
        adminPort: AdminPort,
        errorService: AirErrorService,
        storagePort: AdminStoragePort,
        notificationService: NotificationService,
        storageAdapter: StoragePort,
    ) {
        this._adminPort = adminPort;
        this._errorService = errorService;
        this._adminStoragePort = storagePort;
        this._notificationService = notificationService;
        this._storageAdapter = storageAdapter;
    }

    async updateConfigByKey<T extends object>(
        key: ConfigKey,
        value: T,
    ): Promise<Option<T>> {
        try {
            this._errorService.clear(AirErrorKind.AdminUpdateConfig);
            this._adminStoragePort.isUpdateConfigQueryInProgress.set(true);

            const res = await this._adminPort.updateConfigByKey<T>(
                key,
                JSON.stringify(value),
            );

            this._adminStoragePort.isUpdateConfigQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._adminStoragePort.isUpdateConfigQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.AdminUpdateConfig,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updateBrandingConfig(
        newConfig: BrandingConfig,
    ): Promise<Option<BrandingConfig>> {
        try {
            this._errorService.clear(AirErrorKind.AdminUpdateConfig);
            this._adminStoragePort.isUpdateConfigQueryInProgress.set(true);

            if (
                newConfig.logo !== null &&
                newConfig.logo?.indexOf("base64") !== -1
            ) {
                newConfig.logo = newConfig.logo.split(",")[1];
            } else {
                newConfig.logo = null;
            }

            if (
                newConfig.icon !== null &&
                newConfig.icon?.indexOf("base64") !== -1
            ) {
                newConfig.icon = newConfig.icon.split(",")[1];
            } else {
                newConfig.icon = null;
            }

            await this._adminPort.updateConfigByKey(
                ConfigKey.Branding,
                JSON.stringify(newConfig),
            );

            this._adminStoragePort.isUpdateConfigQueryInProgress.set(false);
            this._notificationService.show(
                "Branding successfully updated",
                "success",
            );

            return newConfig;
        } catch (error) {
            this._adminStoragePort.isUpdateBrandingConfigQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.AdminUpdateConfig,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async getPreview(): Promise<Option<PreviewBaseInfo>> {
        try {
            this._errorService.clear(AirErrorKind.GetFeature);
            this._storageAdapter.preview.isGetQueryInProgress.set(true);

            const res = await this._adminPort.getFeatureConfigByKey<
                Option<PreviewBaseInfo>
            >(ConfigKey.Preview);

            this._storageAdapter.preview.isGetQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._storageAdapter.preview.isGetQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.GetFeature,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updatePreview(
        previewBaseInfo: PreviewUpdatePreviewParams,
    ): Promise<boolean> {
        try {
            this._errorService.clear(AirErrorKind.UpdateFeature);
            this._storageAdapter.preview.isUpdateQueryInProgress.set(true);

            await this.updateConfigByKey(ConfigKey.Preview, previewBaseInfo);

            this._storageAdapter.preview.isUpdateQueryInProgress.set(false);

            return true;
        } catch (error) {
            this._storageAdapter.preview.isUpdateQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.UpdateFeature,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);

            return false;
        }
    }

    async getAI(): Promise<Option<AIFeatureConfig>> {
        try {
            this._errorService.clear(AirErrorKind.GetFeature);
            this._storageAdapter.ai.isGetQueryInProgress.set(true);

            const res = await this._adminPort.getFeatureConfigByKey<
                Option<AIFeatureConfig>
            >(ConfigKey.AI);

            this._storageAdapter.ai.isGetQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._storageAdapter.ai.isGetQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.GetFeature,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updateAI(disabled: boolean): Promise<boolean> {
        try {
            this._errorService.clear(AirErrorKind.UpdateFeature);
            this._storageAdapter.ai.isUpdateQueryInProgress.set(true);

            await this.updateConfigByKey(ConfigKey.AI, { disabled: disabled });

            this._storageAdapter.ai.isUpdateQueryInProgress.set(false);

            return true;
        } catch (error) {
            this._storageAdapter.ai.isUpdateQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.UpdateFeature,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);

            return false;
        }
    }

    async getSharingFeatures(): Promise<Option<ShareLinkUIConfig>> {
        try {
            this._errorService.clear(AirErrorKind.GetFeature);
            this._storageAdapter.sharing.isGetSharingDetailsQueryInProgress.set(
                true,
            );

            const res = await this._adminPort.getFeatureConfigByKey<
                Option<ShareLinkUIConfig>
            >(ConfigKey.Sharing);

            this._storageAdapter.sharing.isGetSharingDetailsQueryInProgress.set(
                false,
            );

            return res;
        } catch (error) {
            this._storageAdapter.sharing.isGetSharingDetailsQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.GetFeature,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updateSharingFeatures(
        data: Record<string, string>,
    ): Promise<boolean> {
        try {
            this._errorService.clear(AirErrorKind.UpdateFeature);
            this._storageAdapter.sharing.isGetSharingDetailsQueryInProgress.set(
                true,
            );

            await this.updateConfigByKey(ConfigKey.Sharing, data);

            this._storageAdapter.sharing.isGetSharingDetailsQueryInProgress.set(
                false,
            );

            return true;
        } catch (error) {
            this._storageAdapter.sharing.isGetSharingDetailsQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.UpdateFeature,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);

            return false;
        }
    }
}
