import {
    Button,
    Caption1,
    Checkbox,
    Field,
    Text,
    Textarea,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { state } from "../../../../state/stateAdapter";
import { useAdmin } from "../../../../../application/useCases/useAdmin";
import {
    ConfigKey,
    ShareLinkUIConfig,
} from "../../../../../application/config/config";
import { useConfig } from "../../../../../application/useCases/useConfig";
import { apiCall } from "../../../../../infrastructure/api/api";
import { useNotification } from "../../../../../application/useCases/useNotification";

function splitEmails(emails: string): string[] {
    return emails
        .trim()
        .replace(/\s+/g, ",")
        .split(",")
        .map(email => email.trim())
        .filter(email => email.length > 0);
}

function formatEmails(emails: string[]): string {
    return emails.join(", ");
}

function Sharing(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const config = state.useState(useStorage.config.config);
    const shareLinkUiConfiguration = state.useState(useStorage.config.config, {
        properties: ["features.shareLinkUiConfiguration"],
    }).features.shareLinkUiConfiguration;
    const requestAssetsFeature = state.useState(useStorage.config.config, {
        properties: ["features.assetAccessRequestEnabled"],
    }).features.shareLinkUiConfiguration.assetAccessRequestEnabled;
    const isAdminUpdateConfigQueryInProgress = state.useState(
        useStorage.admin.isUpdateConfigQueryInProgress,
    );
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );

    const [isLoading, setIsLoading] = useState(false);
    const [
        isAssetAccessRequestRecipientsQueryInProgress,
        setIsAssetAccessRequestRecipientsQueryInProgress,
    ] = useState(true);
    const [shareByLinkVariant, setShareByLinkVariant] = useState(
        shareLinkUiConfiguration.plainLink,
    );
    const [shareMSEIDVariant, setShareMSEIDVariant] = useState(
        shareLinkUiConfiguration.domainOnly,
    );
    const [shareWithPasswordVariant, setShareWithPasswordVariant] = useState(
        shareLinkUiConfiguration.password,
    );
    const [requestAssetsEnabled, setRequestAssetsEnabled] =
        useState(requestAssetsFeature);
    const [assetAccessRequestRecipients, setAssetAccessRequestRecipients] =
        useState(
            config.features.shareLinkUiConfiguration
                .assetAccessRequestRecipients ?? [],
        );
    const [
        assetAccessRequestRecipientsDraft,
        setAssetAccessRequestRecipientsDraft,
    ] = useState(assetAccessRequestRecipients);
    const [emailsToShow, setEmailsToShow] = useState<string>(
        formatEmails(assetAccessRequestRecipientsDraft),
    );

    const isSharingDisabled =
        shareByLinkVariant === false &&
        shareMSEIDVariant === false &&
        shareWithPasswordVariant === false;

    const areVariantsTheSame =
        shareLinkUiConfiguration.plainLink === shareByLinkVariant &&
        shareLinkUiConfiguration.domainOnly === shareMSEIDVariant &&
        shareLinkUiConfiguration.password === shareWithPasswordVariant &&
        requestAssetsFeature === requestAssetsEnabled &&
        (requestAssetsEnabled
            ? `${assetAccessRequestRecipients}` ===
              `${assetAccessRequestRecipientsDraft}`
            : true);

    const areActionsDisabled = areVariantsTheSame;

    const onSave = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        const res = await useAdmin.updateConfigByKey<ShareLinkUIConfig>(
            ConfigKey.Sharing,
            {
                plainLink: shareByLinkVariant,
                domainOnly: shareMSEIDVariant,
                password: shareWithPasswordVariant,
                emailNotifications: false,
                enabled: !isSharingDisabled,
                assetAccessRequestEnabled: requestAssetsEnabled,
                assetAccessRequestRecipients: assetAccessRequestRecipientsDraft,
            },
        );

        if (res) {
            await useConfig.updateConfigByKey(ConfigKey.Sharing, res);

            setAssetAccessRequestRecipients(res.assetAccessRequestRecipients);
            setAssetAccessRequestRecipientsDraft(
                res.assetAccessRequestRecipients,
            );
            setEmailsToShow(formatEmails(res.assetAccessRequestRecipients));
        } else {
            await useConfig.getConfig();
        }

        setIsLoading(false);
    }, [
        shareByLinkVariant,
        shareMSEIDVariant,
        shareWithPasswordVariant,
        isSharingDisabled,
        requestAssetsEnabled,
        assetAccessRequestRecipientsDraft,
    ]);

    const onDiscard = useCallback((): void => {
        setShareByLinkVariant(shareLinkUiConfiguration.plainLink);
        setShareMSEIDVariant(shareLinkUiConfiguration.domainOnly);
        setShareWithPasswordVariant(shareLinkUiConfiguration.password);
    }, [
        shareLinkUiConfiguration.domainOnly,
        shareLinkUiConfiguration.password,
        shareLinkUiConfiguration.plainLink,
    ]);

    const testEmails = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const res = await apiCall(
                "/api/admin/features/shareLinkUiConfiguration/testEmail",
                {
                    method: "POST",
                },
            );

            if (!res.ok) {
                throw new Error("Request failed");
            }

            useNotification.show("Emails sent successfully", "success");
        } catch (error) {
            useNotification.show("Failed to send emails", "error");
        }

        setIsLoading(false);
    };

    useEffect(() => {
        setIsAssetAccessRequestRecipientsQueryInProgress(true);
        const sharingFeatures = async (): Promise<void> => {
            const res = await useAdmin.getSharingFeatures();

            if (res) {
                setAssetAccessRequestRecipients(
                    res.assetAccessRequestRecipients,
                );
                setAssetAccessRequestRecipientsDraft(
                    res.assetAccessRequestRecipients,
                );
                setEmailsToShow(formatEmails(res.assetAccessRequestRecipients));
            }

            setIsAssetAccessRequestRecipientsQueryInProgress(false);
        };

        sharingFeatures();
    }, []);

    if (
        isAdminUpdateConfigQueryInProgress ||
        isUpdateConfigQueryInProgress ||
        isLoading ||
        isAssetAccessRequestRecipientsQueryInProgress
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            buttons={[
                {
                    title: "Save changes",
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled: areActionsDisabled,
                    onClick: onSave,
                },
                {
                    title: "Discard changes",
                    onClick: onDiscard,
                    appearance: "subtle",
                    disabled: areActionsDisabled,
                },
            ]}
        >
            <form className="admin__form">
                <section className="common-settings-section">
                    <section className="common-settings-fields">
                        <div>
                            <Text weight="semibold">
                                Allowed variants to share content
                            </Text>

                            <br />
                            {isSharingDisabled ? (
                                <>
                                    <Caption1>
                                        If no options are selected, sharing is
                                        disabled across an app regardless
                                        permissions set
                                    </Caption1>
                                </>
                            ) : null}
                            <br />

                            <div className={commonClasses.hContainer}>
                                <Checkbox
                                    checked={shareMSEIDVariant}
                                    onChange={(): void =>
                                        setShareMSEIDVariant(!shareMSEIDVariant)
                                    }
                                    label="Microsoft Entra ID (applied to Internal tenant only)"
                                />

                                <Checkbox
                                    checked={shareWithPasswordVariant}
                                    onChange={(): void =>
                                        setShareWithPasswordVariant(
                                            checked => !checked,
                                        )
                                    }
                                    label="Plain Password"
                                />

                                <Checkbox
                                    checked={shareByLinkVariant}
                                    onChange={(): void =>
                                        setShareByLinkVariant(
                                            !shareByLinkVariant,
                                        )
                                    }
                                    label="Anyone with the link"
                                />
                            </div>

                            <div style={{ marginTop: "24px" }}>
                                <Checkbox
                                    checked={requestAssetsEnabled}
                                    onChange={(): void =>
                                        setRequestAssetsEnabled(
                                            !requestAssetsEnabled,
                                        )
                                    }
                                    label="Allow send emails with the list of selected assets"
                                />

                                {requestAssetsEnabled ? (
                                    <>
                                        <Field
                                            label="Target emails"
                                            style={{ marginTop: "12px" }}
                                        >
                                            <Textarea
                                                resize="vertical"
                                                placeholder="Enter emails separated by comma"
                                                defaultValue={emailsToShow}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLTextAreaElement>,
                                                ): void => {
                                                    const emails = splitEmails(
                                                        event.target.value,
                                                    );

                                                    setAssetAccessRequestRecipientsDraft(
                                                        emails,
                                                    );
                                                }}
                                            />
                                        </Field>

                                        <Button
                                            style={{ marginTop: "12px" }}
                                            disabled={
                                                emailsToShow.length === 0 ||
                                                formatEmails(
                                                    assetAccessRequestRecipients,
                                                ) !==
                                                    formatEmails(
                                                        assetAccessRequestRecipientsDraft,
                                                    )
                                            }
                                            onClick={testEmails}
                                            title={
                                                formatEmails(
                                                    assetAccessRequestRecipients,
                                                ) !==
                                                formatEmails(
                                                    assetAccessRequestRecipientsDraft,
                                                )
                                                    ? "You have to save new emails first"
                                                    : ""
                                            }
                                        >
                                            Test emails
                                        </Button>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </section>
                </section>
            </form>
        </AdminLayout>
    );
}

export default Sharing;
